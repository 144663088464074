import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EmbeddedViewRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as Get from '@npm-libs/ng-getx';
import {
  DataQuery,
  DataReferenceConfig,
  DataReferencePipe,
  ViewComponent,
  ViewConfig,
  parseDataReference,
  viewComponentInputs,
  viewRegistry,
} from '@npm-libs/ng-templater';
import { IntlModule } from '@rcg/intl';
import { isNonNullable } from '@rcg/utils/type.utils';
import {
  CalculatedFieldService,
  DrillThrough,
  FieldListService,
  GroupingBarService,
  ICalculatedFieldSettings,
  IConditionalFormatSettings,
  IDataOptions,
  IDrillOptions,
  IFieldOptions,
  IFilter,
  IFormatSettings,
  IGroupSettings,
  ISort,
  PivotView,
  PivotViewComponent,
  PivotViewModule,
} from '@syncfusion/ej2-angular-pivotview';
import { DataManager } from '@syncfusion/ej2-data';
import * as dot from 'dot-object';
import { combineLatest, debounceTime, filter } from 'rxjs';
import { TemplaterSFDataAdaptor } from '../sf-data-adaptor';

PivotView.Inject(DrillThrough);

const name = 'pivot-table';
type Name = typeof name;

export type PivotTableViewProps = {
  data?: DataReferenceConfig;
  queryRuntimeDataPath?: string;
  expandAll?: boolean;
  enableSorting?: boolean;
  showGroupingBar?: boolean;
  allowGrouping?: boolean;
  showFieldList?: boolean;
  showGrandTotals?: boolean;
  showSubTotals?: boolean;
  columns?: IFieldOptions[];
  rows?: IFieldOptions[];
  values?: IFieldOptions[];
  filters?: IFieldOptions[];
  fieldMapping?: IFieldOptions[];
  excludeFields?: string[];
  //? https://ej2.syncfusion.com/documentation/pivotview/drill-through
  drilledMembers?: IDrillOptions[];
  //? https://ej2.syncfusion.com/documentation/pivotview/sorting
  sortSettings?: ISort[];
  //? https://ej2.syncfusion.com/documentation/pivotview/filtering
  filterSettings?: IFilter[];
  //? https://ej2.syncfusion.com/documentation/pivotview/grouping
  groupSettings: IGroupSettings[];
  //? https://ej2.syncfusion.com/documentation/pivotview/number-formatting
  formatSettings?: IFormatSettings[];
  //? https://ej2.syncfusion.com/documentation/pivotview/conditional-formatting
  conditionalFormatSettings?: IConditionalFormatSettings[];
  //? https://ej2.syncfusion.com/documentation/pivotview/calculated-field
  calculatedFieldSettings?: ICalculatedFieldSettings[];
  displayOption: PivotView['displayOption'];
  gridSettings: PivotView['gridSettings'];
  chartSettings: PivotView['chartSettings'];
};

export type PivotTableViewConfig = ViewConfig<Name, PivotTableViewProps>;

@Get.NgAutoDispose
@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  templateUrl: './pivot-table.view.component.html',
  styleUrls: ['./pivot-table.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GroupingBarService, FieldListService, CalculatedFieldService],
  imports: [CommonModule, DataReferencePipe, PivotViewModule, MatButtonModule, MatIconModule, MatTooltipModule, IntlModule],
})
export class PivotTableViewComponent
  extends ViewComponent<Name, PivotTableViewProps, PivotTableViewConfig>
  implements OnInit, OnChanges, OnDestroy
{
  private vcRef = inject(ViewContainerRef);

  @ViewChild('pivot') pivot?: PivotViewComponent;
  @ViewChild('expandCollapseTemplate') expandCollapseTemplate?: TemplateRef<unknown>;

  private readonly dataManager = new DataManager({
    adaptor: new TemplaterSFDataAdaptor((data) => this.doQuery(data)),
  });

  readonly inDataR = new Get.Rx<unknown, unknown>([]);
  readonly dataR = this.inDataR.pipe(debounceTime(200));

  private lastDataQuery?: string;

  private expandCollapseDrillMem?: PivotViewComponent['dataSourceSettings']['drilledMembers'];
  private expandCollapseViewRef?: EmbeddedViewRef<unknown>;

  private doQuery(query: DataQuery) {
    const newRuntimeDataPath = this.config.props?.queryRuntimeDataPath;

    if (newRuntimeDataPath && JSON.stringify(query) !== this.lastDataQuery) {
      this.lastDataQuery = JSON.stringify(query);

      const newRuntimeData: { [x: string]: unknown } = {};
      dot.set(newRuntimeDataPath, query, newRuntimeData);

      this.patchRuntimeData(newRuntimeData);
    }

    return this.dataR.value;
  }

  private dataSourceSettingsR = new Get.Rx<IDataOptions | null>(null);
  public dataSourceSettings$ = this.dataSourceSettingsR.value$.pipe(filter(isNonNullable));

  ngOnInit(): void {
    this.setDataSourceSettings();

    combineLatest([this.dataSourceSettingsR.value$, this.dataR.value$])
      .pipe(debounceTime(1))
      .subscribe(() => this.refreshPivot());

    this.inDataR.subscribeTo(parseDataReference(this.dataReferenceContext, this.config.props?.data));
  }

  override ngOnChanges(changes: SimpleChanges) {
    if ('config' in changes && !changes.config.firstChange) {
      this.setDataSourceSettings();
    }

    return super.ngOnChanges(changes);
  }

  override ngOnDestroy() {
    this.expandCollapseViewRef?.destroy();

    return super.ngOnDestroy();
  }

  private setDataSourceSettings() {
    this.dataSourceSettingsR.data = {
      dataSource: this.dataManager,
      expandAll: this.config.props?.expandAll ?? false,
      enableSorting: this.config.props?.enableSorting ?? false,
      showGrandTotals: this.config.props?.showGrandTotals ?? true,
      showSubTotals: this.config.props?.showSubTotals ?? true,
      columns: this.config.props?.columns,
      rows: this.config.props?.rows,
      values: this.config.props?.values,
      filters: this.config.props?.filters,
      fieldMapping: this.config.props?.fieldMapping,
      excludeFields: this.config.props?.excludeFields,
      drilledMembers: this.config.props?.drilledMembers,
      sortSettings: this.config.props?.sortSettings,
      filterSettings: this.config.props?.filterSettings,
      groupSettings: this.config.props?.groupSettings,
      formatSettings: this.config.props?.formatSettings,
      conditionalFormatSettings: this.config.props?.conditionalFormatSettings,
      calculatedFieldSettings: this.config.props?.calculatedFieldSettings,
    };
  }

  private refreshPivot() {
    try {
      this.pivot?.refresh();
    } catch (error) {
      //ignore - error because pivot is not rendered, will render itself anyway
    }
  }

  public expandAll(): void {
    this.expandCollapseDrillMem = this.pivot!.dataSourceSettings.drilledMembers;
    this.pivot!.dataSourceSettings.drilledMembers = [];
    this.pivot!.dataSourceSettings.expandAll = true;
  }

  public collapseAll(): void {
    this.pivot!.dataSourceSettings.drilledMembers = this.expandCollapseDrillMem;
    this.pivot!.dataSourceSettings.expandAll = false;
  }

  public dataBound() {
    const template = this.expandCollapseTemplate;
    if (!template) {
      console.warn('[Pivot table view]', 'expandCollapseTemplate not available');
      return;
    }

    const parent = this.pivot?.element.querySelector('.e-group-rows');
    if (!parent) {
      console.warn('[Pivot table view]', 'expandCollapseTemplate parent element not available');
      return;
    }

    const viewRef = this.expandCollapseViewRef
      ? this.expandCollapseViewRef
      : (this.expandCollapseViewRef = this.vcRef.createEmbeddedView(template));

    parent.prepend(...viewRef.rootNodes);
  }
}

viewRegistry[name] = {
  component: PivotTableViewComponent,
};
